var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 ml-4",attrs:{"headers":_vm.headers,"items":_vm.delinquents,"page":_vm.page,"items-per-page":_vm.delinquentsPerPage,"server-items-length":_vm.totalDelinquents,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mt-8 mx-4",attrs:{"dense":"","outlined":"","color":"grey","hint":"Filter by Name","persistent-hint":"","solo":""},model:{value:(_vm.delinquentName),callback:function ($$v) {_vm.delinquentName=$$v},expression:"delinquentName"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-btn',{staticClass:"mt-8",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.searchDelinquent()}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-10",attrs:{"rounded":"","disabled":""}},[_vm._v(_vm._s(_vm.currentCenter.name))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}}),_c('v-dialog',{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",staticStyle:{"padding":"20px"},attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"mb-12"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","prepend-icon":"mdi-calendar","readonly":"","hint":"Release Date ","outlined":""},model:{value:(_vm.releaseDate),callback:function ($$v) {_vm.releaseDate=$$v},expression:"releaseDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.releaseDateMenu),callback:function ($$v) {_vm.releaseDateMenu=$$v},expression:"releaseDateMenu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":new Date().toISOString().substr(0, 10)},on:{"change":_vm.releaseDateSave},model:{value:(_vm.releaseDate),callback:function ($$v) {_vm.releaseDate=$$v},expression:"releaseDate"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.dishargeReaseons,"label":"Discharge reason","outlined":"","dense":""},model:{value:(_vm.dischargeModel.reason),callback:function ($$v) {_vm.$set(_vm.dischargeModel, "reason", $$v)},expression:"dischargeModel.reason"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","dense":"","clearable":"","auto-grow":"","autofocus":"","flat":"","label":"Discharge Comment"},model:{value:(_vm.dischargeModel.comment),callback:function ($$v) {_vm.$set(_vm.dischargeModel, "comment", $$v)},expression:"dischargeModel.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-12 mt-n10 mb-4",attrs:{"color":"green","disabled":!_vm.valid},on:{"click":function($event){_vm.dischargeDialog = true}}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-n10 mb-4",on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.names",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"btn blue--text",on:{"click":function($event){return _vm.loadProfile(item)}}},[_vm._v(_vm._s(item.delinquent.firstName)+" "+_vm._s(item.delinquent.lastName))])]}},{key:"item.yearOfBirth",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Math.abs(_vm.$moment(item.delinquent.dateOfBirth).format("YYYY"))))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showEdit)?_c('span',{staticClass:"mr-2 btn blue--text",on:{"click":function($event){return _vm.dischargeDialogOpener(item)}}},[_vm._v(" Discharge ")]):_vm._e()]}}])}),_c('v-snackbar',{attrs:{"top":"","color":_vm.color,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.setSnackbar(false)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-dialog',{attrs:{"hide-overlay":"","max-width":"500px"},model:{value:(_vm.dischargeDialog),callback:function ($$v) {_vm.dischargeDialog=$$v},expression:"dischargeDialog"}},[_c('v-card',{attrs:{"tile":"","outlined":""}},[_c('v-card-text',[_c('p',[_vm._v("Are you sure you want to discharge this delinquent?")]),_c('v-btn',{attrs:{"link":"","small":"","color":"blue"},on:{"click":function($event){return _vm.dischargeDelinquent()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"link":"","small":"","color":"blue"},on:{"click":function($event){_vm.dischargeDialog = false}}},[_vm._v("No")])],1)],1)],1),_c('div',{staticClass:"pt-2 px-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.selectDelinquentsPerPage,"label":"Delinquents per page","dense":"","filled":""},model:{value:(_vm.delinquentsPerPage),callback:function ($$v) {_vm.delinquentsPerPage=$$v},expression:"delinquentsPerPage"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"circle":"","total-visible":"7","color":"blue"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm.totalDelinquents)+" Records ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }