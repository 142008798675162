<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="delinquents"
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="delinquentsPerPage"
      :server-items-length="totalDelinquents"
      class="elevation-1 ml-4"
      hide-default-footer
    >
      <template v-slot:top>
        <v-card flat color="white">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="delinquentName"
                    dense
                    outlined
                    color="grey"
                    hint="Filter by Name"
                    persistent-hint
                    class="mt-8 mx-4"
                    solo
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="1">
              <v-btn
                class="mt-8"
                elevation="1"
                fab
                small
                @click="searchDelinquent()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn rounded disabled class="mt-10">{{
              currentCenter.name
            }}</v-btn>
          </v-row>
          <v-row>
            <v-col cols="12" sm="10"> </v-col>
            <v-dialog v-model="dialog" max-width="1200px" persistent>
              <v-card>
                <v-form
                  ref="form"
                  v-model="valid"
                  style="padding: 20px"
                  lazy-validation
                >
                  <v-card-title class="mb-12">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-menu
                            ref="menu"
                            v-model="releaseDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="releaseDate"
                                label="From Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hint="Release Date "
                                outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="releaseDate"
                              :max="new Date().toISOString().substr(0, 10)"
                              @change="releaseDateSave"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="dishargeReaseons"
                            v-model="dischargeModel.reason"
                            label="Discharge reason"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-textarea
                            v-model="dischargeModel.comment"
                            outlined
                            dense
                            clearable
                            auto-grow
                            autofocus
                            flat
                            label="Discharge Comment"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="green"
                      class="ml-12 mt-n10 mb-4"
                      @click="dischargeDialog = true"
                      :disabled="!valid"
                      >Save</v-btn
                    >
                    <v-spacer />
                    <v-btn class="mt-n10 mb-4" @click="close">Cancel</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </template>
      <template v-slot:item.id="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:item.names="{ item }">
        <span @click="loadProfile(item)" class="btn blue--text"
          >{{ item.delinquent.firstName }} {{ item.delinquent.lastName }}</span
        >
      </template>
      <template v-slot:item.yearOfBirth="{ item }">
        <span>{{
          Math.abs($moment(item.delinquent.dateOfBirth).format("YYYY"))
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <span
          @click="dischargeDialogOpener(item)"
          v-if="showEdit"
          class="mr-2 btn blue--text"
        >
          Discharge
        </span>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dischargeDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to discharge this delinquent?</p>
          <v-btn link small color="blue" @click="dischargeDelinquent()"
            >Yes</v-btn
          >
          <v-btn link small color="blue" @click="dischargeDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-col cols="12" sm="2"> {{ totalDelinquents }} Records </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      releaseDate: null,
      releaseDateMenu: false,
      dischargeModel: {
        id: '',
        reason: '',
        comment: '',
        releaseDate: ''
      },
      dishargeReaseons: ['ESCAPED', 'REUNIFIED', 'RELEASED'],
      privileges: null,
      expand: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      delinquentName: null,
      showPanels: false,
      valid: false,
      searchMode: false,
      dialog: false,
      dischargeDialog: false,
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
      totalPages: 0,
      totalDelinquents: 0,
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      singleSelect: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'number'
        },
        { text: 'Names', value: 'names' },
        { text: 'Year of birth', value: 'yearOfBirth' },
        { text: 'District of birth', value: 'delinquent.placeOfBirth.name' },
        { text: 'Arrival date', value: 'arrivalDate' },
        { text: 'Intake', value: 'apprehension.apprehensionDate' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },

  computed: {
    ...mapGetters('delinquent', ['delinquents', 'totalDelinquent']),
    // ...mapGetters('psychotherapy', [
    //   'progressNotes',
    //   'progressNotesByDate',
    //   'assessmentPsychotherapyQuestions',
    //   'assessmentPsychotherapyAnswersByQuestion',
    //   'assessmentPsychotherapyQuestionsByPrecedence'
    // ]),
    formTitle () {
      return 'Discharge Delinquent'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadAllDelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAllDelinquents()
      }
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.loadAllDelinquents()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.showPanels = true
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    otherSelected (id) {
      return this.selectedOptions
    },
    ...mapActions('delinquent', ['readDelinquents', 'dischargeDelinquents']),
    ...mapActions('store', ['switchToClass']),
    ...mapActions('psychotherapy', [
      'addProgressNotes',
      'readProgressNotes',
      'getAssessmentPsychotherapyQuestions',
      'updateProgressNote',
      'removeProgressNote'
    ]),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquentItem: 'SET_DELINQUENT_ITEM'
    }),
    releaseDateSave (date) {
      this.$refs.menu.save(date)
      this.dischargeModel.releaseDate = this.$moment(date).format(
        'DD-MMM-YYYY'
      )
    },
    searchDelinquent () {
      this.searchMode = true
    },
    dischargeDialogOpener (item) {
      this.dialog = true
      this.dischargeModel.id = item.id
    },
    dischargeDelinquent () {
      if (this.$refs.form.validate()) {
        this.dischargeDialog = false
        this.$delinquentService
          .dischargerDelinquent(this.dischargeModel)
          .then(response => {
            if (response.status === 'success') {
              this.dischargeDelinquents(this.dischargeModel.id)
              this.close()
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      }
    },
    close () {
      this.dialog = false
      this.$refs.form.reset()
    },
    loadProfile (item) {
      this.setDelinquentItem(item)
      this.$router.push('/delinquent-profile')
    },
    loadAllDelinquents () {
      const pageNumber = this.page - 1
      this.states = 'REHABILITATION,ADMITTED'
      this.size = this.delinquentsPerPage
      this.sort = 'apprehension.delinquent.lastName,desc'
      this.$delinquentService
        .fetchAllTransfersSpecification(
          this.delinquentFirstName,
          this.delinquentLastName,
          this.placeOfBirthId,
          this.residenceId,
          this.recordDates,
          this.arrivalDates,
          this.exitDates,
          this.released,
          this.states,
          this.eligibilityStates,
          this.intakeId,
          this.intakeSet,
          this.decisionIds,
          this.physicallyScreened,
          this.screeningApprovalStatuses,
          this.onGraduationList,
          this.graduationId,
          this.expand,
          this.graduationApprovalStatuses,
          this.postRehabDestinationId,
          this.hasOccupation,
          this.organizationId,
          pageNumber,
          this.delinquentsPerPage,
          this.sort
        )
        .then(response => {
          this.totalPages = response.totalPages
          if (!response.status) {
            this.readDelinquents(response.content)
            this.totalDelinquents = response.totalElements
            response.content.forEach(element => {
              element.number = response.content.indexOf(element) + 1
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.editProgressMode) {
        this.editProgressNote(this.progressNote)
      } else {
        //         selectedOutcomeMeasure: {
        //   id: '',
        //   other: ''
        // },
        // selectedMedicalCondition: [],
        // selectedTechnique_used: [],
        // selectedPatient_response: [],
        // selectedTreatment_plan_action: '',
        // this.progressNotes.selectedOptions.push({
        //   this.selectedMedicalCondition
        // })
        this.selectedMedicalCondition.forEach(el => {
          this.progressNote.selectedOptions.push({ selectedOptionID: el })
        })

        this.selectedTechnique_used.forEach(el => {
          this.progressNote.selectedOptions.push({ selectedOptionID: el })
        })

        this.selectedPatient_response.forEach(el => {
          this.progressNote.selectedOptions.push({ selectedOptionID: el })
        })
        this.progressNote.selectedOptions.push({
          selectedOptionID: this.selectedTreatment_plan_action
        })
        this.progressNote.selectedOptions.push({
          selectedOptionID: this.selectedOutcomeMeasure
        })
        this.$psychotherapyService
          .addProgressNotes(this.progressNote)
          .then(response => {
            if (response !== null) {
              this.addProgressNotes(response)
              this.close()
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      }
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1 ||
      this.privileges.indexOf('UPDATE_DISCHARGEINFO') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1 ||
      this.privileges.indexOf('UPDATE_DISCHARGEINFO') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1 ||
      this.privileges.indexOf('UPDATE_DISCHARGEINFO') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
